import React from "react"
import { motion } from "framer-motion"
import PropTypes from "prop-types"

import "./quiz.css"
/* import '../../styles/global.css'; */

function AnswerOption(props) {
  function donothing() {}

  function handleKeyDown(e) {
    if (e.keyCode === 13) {
      props.onAnswerSelected(props.answerIndex)
    }
  }

  const variants = {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 },
      },
    },
    closed: {
      y: -50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 },
      },
    },
  }
  return (
    <div className={`answer-wrapper-${props.id}`}>
      <motion.li
        initial="closed"
        variants={variants}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.95 }}
        ref={props.parentRef}
        onClick={
          props.disabled
            ? donothing()
            : () => props.onAnswerSelected(props.answerIndex)
        }
        onKeyDown={props.disabled ? donothing() : e => handleKeyDown(e)}
        tabIndex="0"
        className={`answer-animation-${props.id} li-${props.questionId}-${props.id} answer-label bubble-default d-flex justify-content-center align-items-center`}
      >
        <input
          type="radio"
          className="radioCustomButton"
          name="radioGroup"
          id={props.id}
          value={props.type}
          disabled={props.disabled}
        />
        <label
          className={`label-${props.questionId}-${props.id} answer-label`}
          htmlFor={props.id}
        >
          {props.answerContent}
        </label>
      </motion.li>
    </div>
  )
}

AnswerOption.propTypes = {
  answerContent: PropTypes.string.isRequired,
  onAnswerSelected: PropTypes.func.isRequired,
}

export default AnswerOption
