import React from "react"
import { motion } from "framer-motion"

import "./quiz.css"

function FactSquare(props) {
  const factVariants = {
    open: {
      opacity: 1,
      transition: {},
    },
    closed: {
      opacity: 0,
      transition: {},
    },
  }
  return (
    <motion.div className="flip-card col-6 col-md-3 p-0">
      <motion.div variants={factVariants} className="flip-card-inner">
        <div
          className="flip-card-front py-2 px-1 p-sm-2 pt-sm-3 pt-md-1 p-lg-4 pt-lg-5"
          style={{ backgroundColor: `${props.bgColor}` }}
        >
          {" "}
          <h5 className="weight-900">{props.head}</h5>
          <p>{props.body}</p>
        </div>
        <motion.div
          variants={factVariants}
          className="flip-card-back"
          style={{ backgroundColor: `${props.bgColor}` }}
        ></motion.div>
      </motion.div>
    </motion.div>
  )
}

export default FactSquare
