import React from "react"
import PropTypes from "prop-types"
import { motion } from "framer-motion"

import "./quiz.css"
/* import '../../styles/global.css'; */

function Question(props) {
  const variants = {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 },
      },
    },
    closed: {
      y: -50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 },
      },
    },
    clicked: {
      backgroundcolor: "#00000",
      opacity: 1,
      transition: {
        y: { stiffness: 1000 },
      },
    },
  }
  return (
    <motion.div
      initial="open"
      className={`question-${props.id} bubble-question mx-auto bg-secondary text-center d-flex align-items-center justify-content-center`}
    >
      <h2 className="weight-900">{props.question}</h2>
    </motion.div>
  )
}

Question.propTypes = {
  question: PropTypes.string.isRequired,
}

export default Question
