import React from "react"
import PropTypes from "prop-types"

import HeroImage from "../images/hero-image"

const Header = ({ siteTitle }) => (
  <section className="bg-light color-white d-flex flex-column justify-content-center position-relative ">
    <div className="container">
      <div className="row mt-3 mt-md-5">
        <div className="col-10 col-md-6 text-center offset-1 offset-md-3">
          <h2 className="hero-header-1 weight-900 mb-4">
            Hartz 4 und der Tag gehört dir?
          </h2>
          <h3 className="weight-200 hero-header-3 pb-md-0">
            Von 5,8 Millionen Hartz-4-Beziehenden sind ganze{" "}
            <span className="weight-900">76% nicht arbeitslos</span> - schon
            gewusst?
          </h3>
        </div>
      </div>
      <div className="row p-0">
        <div className="col-10 col-md-8 offset-1 offset-md-2 p-0 z-index-5">
          <HeroImage />
        </div>
      </div>
    </div>
  </section>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
