const quizQuestions = [
  {
    question: "Wieviel bekommen Hartz 4 Beziehende monatlich für Bildung?",
    id: 1,
    answers: [
      {
        is_correct: false,
        content: "94,32 €",
        answer_message: "Voll daneben!",
        id: 1,
      },
      {
        is_correct: false,
        content: "12,54 €",
        answer_message: "Schön wär's!",
        id: 2,
      },
      {
        is_correct: true,
        content: "1,12 €",
        answer_message: "Ganz genau!",
        id: 3,
      },
    ],
    info_text:
      '<p><span class="weight-900">Mythos: Hartz 4 reicht zum Leben:</span> 1,12 € für Bildung, das ist kein Scherz. Seit Jahren wird der Regelsatz systematisch kleingerechnet. Am Ende des Geldes ist dann häufig noch sehr viel Monat übrig. Für Nahrungsmittel etwa haben Erwachsene in Hartz 4 im Monat nur 150,60 Euro zur Verfügung, nicht einmal fünf Euro am Tag.</p><p>Für Cafe- oder Restaurantbesuche sind monatlich 10,76 Euro vorgesehen, für Gesundheitspflege gerade mal 16,42 Euro. Auf welchen Posten man auch schaut: Das reicht hinten und vorne nicht. Höchste Zeit, das zu ändern!</p>',
    info_link: "https://de.wikipedia.org/wiki/Arbeitslosengeld_II",
  },
  {
    question: "Bekommen Hartz-4-Familien auch Kindergeld?",
    id: 2,
    answers: [
      {
        is_correct: false,
        content: "Ja klar, wie alle anderen auch.",
        answer_message: "Voll daneben!",
        id: 1,
      },
      {
        is_correct: true,
        content:
          "Ja, aber das Kindergeld wird vollständig mit Hartz IV verrechnet.",
        answer_message: "Schön wär's!",
        id: 2,
      },
      {
        is_correct: false,
        content:
          "Nein, der Kinderbedarf ist über den Regelsatz vollumfänglich abgedeckt.",
        answer_message: "Ganz genau!",
        id: 3,
      },
    ],
    info_text:
      '<p class="weight-900">Alle Kinder sind gleich, aber manche sind gleicher.</p><p>Kindergeld für alle, aber nicht für die Ärmsten. Es ist die einzige “bedingungslose” Leistung vom Staat, egal, wieviel Geld jemand verdient. Bei Hartz 4 wird es aber vollständig mit dem Regelsatz verrechnet und so haben die Familien gar nichts davon. Das betrifft fast 2 Mio. Kinder und Jugendliche in Hartz-4-Haushalten.</p>',
    info_link: "https://de.wikipedia.org/wiki/Otto_(Vorname)",
  },
  {
    question:
      "Können Erwachsenen die Leistungen gekürzt (sanktioniert) werden, wenn Kinder im Haushalt leben?",
    id: 3,
    answers: [
      {
        is_correct: false,
        content:
          "Nein, Haushalte mit Kindern genießen einen besonderen Schutz.",
        answer_message: "Voll daneben!",
        id: 1,
      },
      {
        is_correct: false,
        content: "Ja, aber sie dürfen nur um max. 10% gekürzt werden.",
        answer_message: "Schön wär's!",
        id: 2,
      },
      {
        is_correct: true,
        content: "Ja, auch Eltern sind häufig von Sanktionen betroffen.",
        answer_message: "Ganz genau!",
        id: 3,
      },
    ],
    info_text:
      '<p><span class="weight-900">Mitgehangen, mitgefangen:</span> Ende 2018 lebten rund 80.000 Kinder und Jugendliche in Haushalten, in denen mindestens eine erwachsene Person sanktioniert wurde. Bis zum Urteil des Bundesverfassungsgerichts im November 2019 konnten Haushalte mit Kindern sogar vollsanktioniert, also die Leistungen komplett gestrichen werden. Zur Zeit können immer noch bis zu 30% gekürzt werden. Das ist allerdings nur eine Übergangsregelung. Neue Sanktionsgesetze werden derzeit ausgearbeitet.. BMAS, we are watching!</p>',
    info_link:
      "https://www.tagesspiegel.de/politik/linke-fordert-abschaffung-80-000-kinder-waren-ende-2018-von-hartz-iv-sanktionen-betroffen/25252488.html",
  },
  {
    question: "Werden die Wohnkosten vom Amt übernommen?",
    id: 4,
    answers: [
      {
        is_correct: true,
        content: "Nur “angemessene” Wohnkosten werden übernommen.",
        answer_message: "Ganz genau!",
        id: 1,
      },
      {
        is_correct: false,
        content: "Ja. Da muss man sich keine Sorgen machen.",
        answer_message: "Schön wär's!",
        id: 2,
      },
      {
        is_correct: false,
        content:
          "Ja, aber nur mit Wohnberechtigungsschein oder für sehr günstige Wohnungen.",
        answer_message: "Voll daneben!",
        id: 3,
      },
    ],
    info_text:
      '<p class="weight-900">Wohnen in Armut ist, wenn man vom Minimum noch draufzahlen muss.</p><p>Übernommen werden nur “angemessene” Kosten und das Wohnen wird immer teurer. Nach Angaben des Sozialwissenschaftlers Dr. Andrej Holm, HU Berlin, waren 2015 zum Beispiel in Berlin nicht einmal 5 Prozent der Wohnungsangebote für das Amt “angemessen”. Die Kriterien der Angemessenheit sind kommunal unterschiedlich und richten sich nachweislich nicht nach dem realen Wohnungsangebot. 2018 wurden bei fast jedem fünften Hartz-4-Beziehenden die Unterkunftskosten nicht vollständig übernommen. Im Schnitt wurden 985 Euro im Jahr nicht übernommen. Viele können so zum Umzug aus ihrer vertrauten Umgebung gezwungen werden. Aufgrund der Corona-Pandemie wurde Betroffenen eine kurze Atempause verschafft: Bis 30.09.2020 werden bei Neu- und Folgeanträgen die tatsächlichen Aufwendungen für Unterkunft und Heizung anerkannt.</p>',
    info_link:
      "https://www.iab-forum.de/analysen-zu-stellenbesetzungsproblemen-konzessionsbereitschaft-reservationslohn-und-suchwege-in-pass/",
  },
  {
    question:
      "Wieviel Vermögen darf man pro Lebensjahr angespart haben, wenn man Hartz 4 bekommt?",
    id: 5,
    answers: [
      {
        is_correct: true,
        content: "150 € pro Lebensjahr.",
        answer_message: "Voll daneben!",
        id: 1,
      },
      {
        is_correct: false,
        content: "750 € pro Lebensjahr.",
        answer_message: "Ganz genau!",
        id: 2,
      },
      {
        is_correct: false,
        content: "300 € pro Lebensjahr.",
        answer_message: "Voll daneben!",
        id: 3,
      },
    ],
    info_text:
      '<p class="weight-900">Das ist das sogenannte Schonvermögen.</p><p>150 € pro Lebensjahr sind leider, je nachdem wie alt man ist, sehr wenig Geld. Wenn beispielsweise ein 51-jähriger nach 25 Jahren Festanstellung gekündigt wurde und in dieser Zeit einiges sparen konnte, muss er sein Vermögen bis auf 7.650 € zuzüglich eines für dringende Anschaffungen reservierten Betrags von 750 € und Rücklagen für die Alterssicherung aufbrauchen, bevor er Hartz 4 bekommt. Das ist problematisch, weil Hartz 4 nicht zum Leben reicht und noch dazu über 50-jährige wesentlich schlechtere Chancen auf dem Arbeitsmarkt haben, als ihre jüngeren Mitstreiter*innen. Altersarmut ist damit häufig vorprogrammiert. Das Schonvermögen sollte deshalb unabhängig vom Alter auf mindestens 20.000 Euro angehoben werden. Bis zum 30.9.2020 gilt im Rahmen von beschlossenen Erleichterungen in der Corona-Pandemie, dass bei Neuanträgen Vermögen (insbesondere selbstgenutztes Wohneigentum und Vermögen, das der Altersvorsorge dient) außer Betracht bleibt, soweit nicht erhebliche frei verfügbare Barvermögen bestehen.</p>',
    info_link:
      "https://www.iab-forum.de/analysen-zu-stellenbesetzungsproblemen-konzessionsbereitschaft-reservationslohn-und-suchwege-in-pass/",
  },
  {
    question: "Dürfen sich Hartz-4-Beziehende frei in Deutschland bewegen? ",
    id: 6,
    answers: [
      {
        is_correct: false,
        content:
          "Ja, wer dem zuständigen Jobcenter rechtzeitig Bescheid gibt, kann sich frei bewegen, egal wohin.",
        answer_message: "Voll daneben!",
        id: 1,
      },
      {
        is_correct: false,
        content:
          "Klar, alle Bürger*innen genießen Freizügigkeit im gesamten Bundesgebiet. Das ist im Grundgesetz mit Artikel 11 fest verankert.",
        answer_message: "Ganz genau!",
        id: 2,
      },
      {
        is_correct: true,
        content:
          "Nein. Die “Ortsabwesenheit” muss grundsätzlich beantragt und bewilligt werden. Dazu gehört auch, dass man sich danach rechtzeitig zurückmeldet. ",
        answer_message: "Voll daneben!",
        id: 3,
      },
    ],
    info_text:
      '<p class="weight-900">Keine Freizügigkeit für Hartz-4 Beziehende.</p><p>Hartz-4-Beziehende müssen sich Urlaub nehmen, wenn sie wegfahren wollen. Das gilt auch für Beerdigungen von Angehörigen oder Besuch beim Kind, das nicht in derselben Stadt wohnt. Etwa 14 Tage vorher muss man die sogenannte “Ortsabwesenheit” beantragen. Max. 21 Tage Urlaub, Wochenenden und Feiertage mit eingerechnet (!) stehen Hartz 4 Beziehenden Urlaub, bzw. Ortsabwesenheit zu. Um nicht sanktioniert zu werden, müssen die Abwesenheiten vorher bewilligt werden. Wer versäumt, sich hinterher rechtzeitig zurückzumelden, riskiert Sanktionen, bzw. den Leistungsanspruch.</p>',
    info_link:
      "https://www.iab-forum.de/analysen-zu-stellenbesetzungsproblemen-konzessionsbereitschaft-reservationslohn-und-suchwege-in-pass/",
  },
  {
    question:
      "Inwieweit war, sind Hartz-4-Beziehende im Vergleich zu Erwerbstätigen in Bezug auf die Corona-Pandemie stärker gefährdet? ",
    id: 7,
    answers: [
      {
        is_correct: true,
        content:
          "Das Risiko durch eine Corona-Infektion im Krankenhaus behandelt werden zu müssen lag bei Hartz-4-Beziehenden um 84,1 Prozent höher, als bei Erwerbstätigen.",
        answer_message: "Voll daneben!",
        id: 1,
      },
      {
        is_correct: false,
        content:
          "Gar nicht. Wenn man sich richtig schützt mit Maske, Abstand und Händewaschen, haben alle, außer die Risikogruppen, das gleiche Infektionsrisiko.",
        answer_message: "Ganz genau!",
        id: 2,
      },
      {
        is_correct: false,
        content:
          "Das Risiko durch eine Infektion im Krankenhaus behandelt werden zu müssen lag bei Hartz-4-Beziehenden um 17,5 Prozent höher als bei Erwerbstätigen.",
        answer_message: "Voll daneben!",
        id: 3,
      },
    ],
    info_text:
      '<p class="weight-900">Die Ärmsten trifft es am härtesten!</p><p>Eine wissenschaftlichen Auswertung von 1,3 Millionen Versicherungsdaten durch die AOK und dem Institut für Medizinische Soziologie des Uniklinikums Düsseldorf hat ergeben, dass Corona eine starke soziale Komponente hat. Je einkommensschwacher die Untersuchten, desto höher das Risiko von Erkrankungen mit schweren oder mittelschweren Verläufen. Studien aus Großbritannien und den USA bestätigen diese Ergebnisse. Die Bundesregierung und der Gesundheitsminister wollten sich zu den Ergebnissen bislang nicht äußern.</p>',
    info_link:
      "https://www.iab-forum.de/analysen-zu-stellenbesetzungsproblemen-konzessionsbereitschaft-reservationslohn-und-suchwege-in-pass/",
  },
  {
    question:
      "Kommen Hartz-4-Beziehende mit ihrem Anteil für Lebensmittel von 150,60 €, hin?",
    id: 8,
    answers: [
      {
        is_correct: false,
        content: "Ja, wenn sie richtig haushalten.",
        answer_message: "Voll daneben!",
        id: 1,
      },
      {
        is_correct: true,
        content:
          "Nicht immer. Rund 1,6 Mio. nutzen zusätzlich die Essensausgabe der Tafel e.V., um monatlich über die Runden zu kommen.",
        answer_message: "Ganz genau!",
        id: 2,
      },
      {
        is_correct: false,
        content:
          "Ja, das ist absolut ausreichend für Lebensmittel, das wurde gewissenhaft ausgerechnet.",
        answer_message: "Voll daneben!",
        id: 3,
      },
    ],
    info_text:
      '<p class="weight-900">Kühlschrank leer?</p><p>Derzeit gibt es etwa 950 Tafeln mit mehr als 2.000 Ausgabestellen. Um die Tafeln nutzen zu können, muss die Hilfebedürftigkeit belegt werden. Etwa die Hälfte der Tafel-Kund*innen kommen aus Haushalten mit erwachsenen SGB-II-Leistungsberechtigten, etwa ein Viertel sind Senior*innen in Rente oder im Grundsicherungsbezug, ungefähr 20 Prozent aus Haushalten, die Leistungen nach dem Asylbewerberleistungsgesetz erhalten. Der Großteil der Menschen, die Angebote der Tafeln in Anspruch nehmen, sind demnach auf existenzsichernde Leistungen der Grundsicherung (in einer ihrer verschiedenen Ausprägungen) angewiesen. Etwa 44 Prozent sind im erwerbsfähigen Alter und 30 Prozent Kinder und Jugendliche.</p>',
    info_link:
      "https://www.iab-forum.de/analysen-zu-stellenbesetzungsproblemen-konzessionsbereitschaft-reservationslohn-und-suchwege-in-pass/",
  },
]

export default quizQuestions
