import React, { useState } from "react"
import "./Newsletter.css"
import NewsletterImg from "../images/newsletter-image"
import addToMailchimp from "gatsby-plugin-mailchimp"

import Facebook from "../../static/icons/facebook-square-brands.svg"
import Twitter from "../../static/icons/twitter-square-brands.svg"
import Instagram from "../../static/icons/instagram-square-brands.svg"

const Newsletter = ({ props }) => {
  const [email, setEmail] = useState("")
  const [age, setAge] = useState("")
  const [loading, setLoading] = useState(false)
  const [res, setRes] = useState("")
  const [resMessage, setResMessage] = useState("")
  const submitNewletter = e => {
    setLoading(true)
    e.preventDefault()
    addToMailchimp(email)
      .then(data => {
        setRes(data.result)
        setResMessage(data.msg)
      })
      .catch(e => console.log(e))
      .finally(e => {
        setLoading("done")
      })
  }
  console.log(resMessage)
  console.log(res)
  return (
    <section id="newsletter">
      <div className="container py-4 font-size-default">
        <div className="col-8 col-md-6 offset-2 offset-md-3 mb-5">
          <NewsletterImg />
        </div>
        <div className={`d-md-flex justify-content-between`}>
          <div className="d-flex "></div>
          <div className="col-md-6 mx-auto pl-0 pr-0">
            {res === "error" ? (
              <h3 className="text-center mb-4 font-size-default">
                {resMessage}
              </h3>
            ) : (
              ""
            )}
            {loading === "done" && res !== "error" ? (
              <h3 className="text-center">Check deine Mails!</h3>
            ) : (
              <div className="text-center w-75 mx-auto">
                <form onSubmit={e => submitNewletter(e)} id="nl_form">
                  <label htmlFor="email" className="label-newsletter mb-3">
                    Hier bekommst du unseren Newsletter
                  </label>

                  <div aria-hidden="true" className="d-none">
                    <input
                      id="age"
                      onChange={e => setAge(e.target.value)}
                      type="text"
                      name="age"
                      tabIndex="-1"
                      value=""
                    />
                  </div>
                  <div>
                    <input
                      type="email"
                      name="EMAIL"
                      onChange={e => setEmail(e.target.value)}
                      id="email"
                      className="corners-round"
                      value={email}
                      placeholder="Deine E-Mail Adresse"
                      required=""
                    />
                    <button
                      className="bg-primary corners-round"
                      type="submit"
                      name="subscribe"
                      id="newsletter-button"
                      disabled={loading === true ? true : false}
                    >
                      <span id="nl_btn_text">
                        {loading === true ? "Wird gesendet..." : "Abschicken"}
                      </span>
                    </button>
                  </div>
                  <div id="response" />
                </form>
              </div>
            )}
            <div className="container mt-3 social-media">
              <div className="row">
                <div className="col text-center">
                  <p className="mb-0">
                    Zu den Social-Media-Kanälen von{" "}
                    <span className="weight-900">Sanktionsfrei</span>
                  </p>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col">
                  <div className="social-media-container d-flex justify-content-center align-items-center mt-2 mb-2 mx-auto">
                    <a
                      target=" _blank"
                      href=" https://www.facebook.com/sanktionsfrei/"
                      rel="noopener noreferrer"
                    >
                      <Facebook />
                    </a>
                    <a
                      target=" _blank"
                      href=" https://twitter.com/sanktionsfrei"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      <Twitter />
                    </a>
                    <a
                      target=" _blank"
                      href=" https://www.instagram.com/sanktionsfrei/"
                      rel="noopener noreferrer"
                    >
                      <Instagram />
                    </a>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col text-center">
                  <p className="mb-0">
                    Zu den Social-Media-Kanälen vom{" "}
                    <span className="weight-900">Paritätischen</span>
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="social-media-container d-flex justify-content-center align-items-center mt-2 mb-2 mx-auto">
                    <a
                      target=" _blank"
                      href="https://www.facebook.com/paritaet/"
                      rel="noopener noreferrer"
                    >
                      <Facebook />
                    </a>
                    <a
                      target=" _blank"
                      href="https://www.twitter.com/paritaet/"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      <Twitter />
                    </a>
                    <a
                      target=" _blank"
                      href="https://www.instagram.com/paritaet/"
                      rel="noopener noreferrer"
                    >
                      <Instagram />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Newsletter
