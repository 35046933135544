import React from "react"

import "./quiz.css"

function InfoBox(props) {
  function createMarkup() {
    return { __html: props.infoText }
  }

  return (
    <div
      className={`info-box mx-auto mt-md-0 ${
        props.isDisabled ? "responsive-enabled" : "responsive-disabled"
      }`}
    >
      {props.answerSelected ? (
        <div>
          <h4
            className={`mb-3 ${
              props.correctAnswer ? "text-success" : "text-danger"
            }`}
          >
            {props.correctAnswer ? "Ganz genau!" : "Voll daneben!"}
          </h4>
          <div
            dangerouslySetInnerHTML={createMarkup()}
            className="info-text pr-md-2"
          ></div>
        </div>
      ) : (
        ""
      )}
    </div>
  )
}

export default InfoBox
