import React, { useState, useEffect } from "react"
import { motion, useCycle, useAnimation, AnimatePresence } from "framer-motion"
import PropTypes from "prop-types"

import "./quiz.css"

import QuizFacts from "./QuizFacts"
import zero from "../../images/Quiz_Ergebnis_0von8.png"
import one from "../../images/Quiz_Ergebnis_1von8.png"
import two from "../../images/Quiz_Ergebnis_2von8.png"
import three from "../../images/Quiz_Ergebnis_3von8.png"
import four from "../../images/Quiz_Ergebnis_4von8.png"
import five from "../../images/Quiz_Ergebnis_5von8.png"
import six from "../../images/Quiz_Ergebnis_6von8.png"
import seven from "../../images/Quiz_Ergebnis_7von8.png"
import eight from "../../images/Quiz_Ergebnis_8von8.png"

function FinalScore(props) {
  const factControls = useAnimation()

  const factVariants = {
    open: {
      opacity: 1,
    },
    closed: {
      opacity: 0,
    },
  }

  function scoreHandler(score) {
    switch (score) {
      case 0:
        return zero
      case 1:
        return one
      case 2:
        return two
      case 3:
        return three
      case 4:
        return four
      case 5:
        return five
      case 6:
        return six
      case 7:
        return seven
      case 8:
        return eight
      default:
        return zero
    }
  }

  function classHandler(props) {
    if (props === true) {
      return "d-block"
    } else return "d-flex justify-content-center align-items-center"
  }

  function imageHandler(props) {
    if (props.isComplete === true && props.animationPlayed === false)
      return (
        <img
          src={scoreHandler(props.score)}
          alt={`Du hast ${props.score} von 8 Fragen richtig beantwortet`}
          className="w-100"
        />
      )
    else if (props.animationPlayed === true) {
      factControls.start("open")
    }
  }

  return (
    <div className={classHandler(props.animationPlayed)}>
      {imageHandler(props)}
      <AnimatePresence>
        <motion.div variants={factVariants} animate={factControls}>
          {" "}
          <QuizFacts variants={factVariants} />
        </motion.div>
      </AnimatePresence>
    </div>
  )
}

FinalScore.propTypes = {
  score: PropTypes.number.isRequired,
}

export default FinalScore
