const quizFacts = [
  {
    id: 1,
    head: "1,12 €",
    body: "ist im Regelsatz monatlich für Bildung vorgesehen.",
    color: "#6e6e6d",
  },
  {
    id: 2,
    head: "100 %",
    body: "vom Kindergeld werden mit dem Regelsatz verrechnet.",
    color: "#D5B6A7",
  },
  {
    id: 3,
    head: "80.000",
    body:
      "Kinder lebten 2018 in einem Haushalt in dem mind. 1 Person sanktioniert wurde.",
    color: "#90A388",
  },
  {
    id: 4,
    head: "5 %",
    body: "der Wohnungen in Berlin gelten für das Jobcenter als angemessen.",
    color: "#706C8C",
  },
  {
    id: 5,
    head: "150 €",
    body: "dürfen pro Lebensjahr angespart werden.",
    color: "#DEC07C",
  },
  {
    id: 6,
    head: "14 Tage",
    body: "im Voraus etwa muss eine Ortsabwesenheit beantragt werden.",
    color: "#D6D1C4",
  },
  {
    id: 7,
    head: "84,1 %",
    body:
      "höhere Warscheinlichkeit für Hartz-4-Beziehende, schwer an Corona zu erkranken.",
    color: "#FFFFFF",
  },
  {
    id: 8,
    head: "1,6 Mio",
    body: "Menschen müssen zusätzlich die Essensausgabe der Tafel nutzen.",
    color: "#B67371",
  },
]

export default quizFacts
