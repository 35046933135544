import React, { useState, useEffect, useRef, createRef } from "react"
import { motion, useCycle, useAnimation, AnimatePresence } from "framer-motion"

import QuizStarter from "../images/QuizStarter"
import Question from "./Question"
import QuizFacts from "./QuizFacts"
import QuestionCount from "./QuestionCount"
import AnswerOption from "./AnswerOption"
import InfoBox from "./InfoBox"
import FinalScore from "./FinalScore"

import quizQuestions from "../../api/quizQuestions"
import "./quiz.css"

function Quiz() {
  const [question, setQuestion] = useState(0)
  const [score, setScore] = useState(0)
  const [isDisabled, setDisabled] = useState(false)
  const [isSelected, setSelected] = useState(0)
  const [isCorrect, setCorrect] = useState(null)
  const [isComplete, setComplete] = useState(false)
  const [quizHeight, setQuizHeight] = useState(260)
  const centerViewRef = useRef(null)
  const questionBoxRef = useRef(null)
  const [quizActive, setQuizActive] = useState(false)
  const [factsActive, setFactsActive] = useState()
  const factControls = useAnimation()
  const quizControls = useAnimation()
  const [animationPlayed, toggleAnimationPlayed] = useState(false)
  const didRender = useRef(false)

  useEffect(() => {
    if (didRender.current) setTimeout(wait, 5000)
    else didRender.current = true
  }, [isComplete])

  function wait() {
    toggleAnimationPlayed(true)
  }

  const [refs] = useState(
    Array(quizQuestions[question].answers.length)
      .fill(0)
      .map(() => createRef())
  )
  const [isOpen, toggleOpen] = useCycle(false, true)
  const answerVariants = {
    open: {
      transition: { staggerChildren: 0.07, delayChildren: 0.2 },
    },
    closed: {
      transition: { delay: 3, staggerChildren: 0.05, staggerDirection: -1 },
    },
  }

  const quizVariants = {
    open: {
      opacity: 1,
    },
    closed: {
      opacity: 0,
      transitionEnd: {
        display: "none",
      },
    },
  }

  const factVariants = {
    open: {
      opacity: 1,
    },
    closed: {
      opacity: 0,
    },
  }

  useEffect(() => {
    if (isSelected) {
      isSelected.value === "true"
        ? isSelected.parentNode.classList.add("bubble-correct")
        : isSelected.parentNode.classList.add("bubble-false")
    }
  }, [isSelected])

  useEffect(() => {
    if (quizActive && !isComplete) {
      let questionHeight = questionBoxRef.current.clientHeight
      setQuizHeight(questionHeight)
    }
  })

  function handleAnswerSelected(i) {
    if (refs[i] && refs[i].current.childNodes[0].value === "true") {
      setScore(score + 1)
      setDisabled(true)
      setCorrect(true)
      setSelected(refs[i].current.childNodes[0])
      toggleOpen(0)
    } else {
      setDisabled(true)
      setCorrect(false)
      setSelected(refs[i].current.childNodes[0])
      toggleOpen(0)
    }
  }

  function nextQuestion() {
    if (question + 1 < quizQuestions.length) {
      toggleOpen(0)
      setQuestion(question + 1)
      setDisabled(false)
      setCorrect(0)
      answerResetter(isSelected)
    } else {
      handleComplete()
    }
  }

  function handleComplete() {
    setComplete(true)
  }

  function handleSkip() {
    toggleAnimationPlayed(true)
    setComplete(true)
  }

  function answerResetter(ref) {
    ref.checked = false
    ref.parentNode.classList.remove("bubble-correct")
    ref.parentNode.classList.remove("bubble-false")
    setSelected(0)
    toggleOpen(1)
  }

  function startAgain() {
    setQuestion(0)
    setScore(0)
    setSelected(0)
    setComplete(0)
    setDisabled(0)
    toggleOpen(1)
  }

  function handleClick() {
    setQuizActive(true)
    toggleOpen(1)
  }

  function sequence() {
    setFactsActive(true)
    factControls.start("open")
  }

  return (
    <div>
      <div className="container px-3">
        <div
          className={`${
            factsActive || animationPlayed
              ? "mt-md-3 pt-md-3 mt-xl-5 pt-xl-5 responsive-fact-dimensions"
              : "d-flex responsive-quiz-dimensions"
          } align-items-center justify-content-center`}
          ref={centerViewRef}
        >
          <AnimatePresence>
            {factsActive && !animationPlayed ? (
              <motion.div variants={factVariants} animate={factControls}>
                {" "}
                <QuizFacts variants={factVariants} />
              </motion.div>
            ) : (
              ""
            )}
          </AnimatePresence>
          <AnimatePresence>
            {isComplete ? (
              <FinalScore
                score={score}
                animationPlayed={animationPlayed}
                isComplete={isComplete}
              />
            ) : (
              <motion.div
                initial={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 1 }}
                animate={quizControls}
                variants={quizVariants}
                className={`${!factsActive ? "d-flex" : "d-none"}`}
              >
                <motion.div
                  variants={quizVariants}
                  className={`${
                    factsActive ? "d-none" : "d-flex"
                  } p-md-2 m-md-0 align-items-center justify-content-center position-relative`}
                >
                  <div className="quiz-illustration col-6">
                    <QuizStarter />
                  </div>
                  <motion.div
                    className="quiz-wrapper-outside"
                    transition={{ duration: 1 }}
                  >
                    <div className="d-flex align-items-center justify-content-center">
                      {quizActive ? (
                        <div className="quiz-wrapper-inside d-flex align-items-center justify-content-center">
                          <div className="question-wrapper d-block mr-md-2">
                            <div ref={questionBoxRef}>
                              <Question
                                isOpen={isOpen}
                                question={quizQuestions[question].question}
                                id={quizQuestions[question].id}
                              />

                              <motion.ul
                                initial="closed"
                                animate={isOpen ? "open" : "closed"}
                                variants={answerVariants}
                                className={`mb-0 answerOptions text-center ${
                                  isDisabled
                                    ? "responsive-disabled"
                                    : "responsive-enabled"
                                }`}
                              >
                                {quizQuestions[question].answers.map(
                                  (answer, i) => (
                                    <AnswerOption
                                      parentRef={refs[i]}
                                      answerIndex={i}
                                      key={i}
                                      id={answer.id}
                                      questionId={quizQuestions[question].id}
                                      answerContent={answer.content}
                                      type={answer.is_correct}
                                      onAnswerSelected={handleAnswerSelected}
                                      disabled={isDisabled}
                                    />
                                  )
                                )}
                              </motion.ul>
                            </div>
                            <div className="info-wrapper d-block d-md-none ml-md-2 mt-3">
                              <InfoBox
                                correctAnswer={isCorrect}
                                answerSelected={isSelected}
                                isDisabled={isDisabled}
                                infoText={quizQuestions[question].info_text}
                                infoLink={quizQuestions[question].info_link}
                              />
                            </div>
                          </div>
                          <div
                            className="info-wrapper d-none d-md-flex ml-md-2"
                            style={{
                              height: quizHeight,
                              backgroundColor: "#333333",
                            }}
                          >
                            <InfoBox
                              correctAnswer={isCorrect}
                              answerSelected={isSelected}
                              infoText={quizQuestions[question].info_text}
                              infoLink={quizQuestions[question].info_link}
                            />
                          </div>
                        </div>
                      ) : (
                        <div>
                          <h3 className="quiz-header text-center pt-5 mb-5 pt-md-3 weight-900">
                            Mach den Faktencheck!
                          </h3>
                          <div className="d-flex justify-content-center row">
                            <button
                              className="my-3 btn-start text-white py-2 px-4"
                              onClick={() => handleClick()}
                            >
                              Quiz starten
                            </button>
                          </div>
                          <div className="d-flex justify-content-center row">
                            <button
                              className="mt-3 btn-facts text-white py-2 px-4"
                              onClick={() => handleSkip()}
                            >
                              Fakten ohne Quiz
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                    {quizActive && !isComplete ? (
                      <div className="my-2">
                        <div className="d-flex justify-content-around">
                          <button
                            onClick={() => nextQuestion()}
                            style={{
                              visibility: isSelected ? "visible" : "hidden",
                            }}
                          >
                            Weiter
                          </button>
                          <QuestionCount
                            counter={question + 1}
                            total={quizQuestions.length}
                          />
                          <span>Score: {score}</span>
                        </div>
                      </div>
                    ) : (
                      " "
                    )}
                  </motion.div>
                </motion.div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  )
}

export default Quiz
