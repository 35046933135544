import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Helena = () => (
  <StaticQuery
    query={graphql`
      query {
        image: file(relativePath: { eq: "hsteinhaus_cropped.jpg" }) {
          childImageSharp {
            fluid(maxHeight: 350) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `}
    render={data => <Img fluid={data.image.childImageSharp.fluid} alt="Helena Steinhaus"/>}
  />
)
export default Helena