import React from "react"
import { motion } from "framer-motion"
import FactSquare from "./FactSquare"

import quizFacts from "../../api/quizFacts"

import "./quiz.css"

function QuizFacts(props) {
  const factVariants = {
    open: {
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.5,
      },
    },
    closed: {
      transition: {
        delay: 1,
        staggerChildren: 0.05,
        staggerDirection: -1,
      },
    },
  }
  return (
    <motion.div className="container my-5 text-center">
      <motion.div initial="closed" variants={factVariants} className="row">
        {quizFacts.map((fact, i) => (
          <FactSquare
            key={i}
            head={fact.head}
            body={fact.body}
            bgColor={fact.color}
          />
        ))}
      </motion.div>
    </motion.div>
  )
}

export default QuizFacts
