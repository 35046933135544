import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Newsletter from "../components/newsletter/Newsletter"
import Hero from "../components/hero/hero"
import Content from "../components/content/content"
import Footer from "../components/footer/Footer"
import Placeholder from "../components/Placeholder/Placeholder"

function IndexPage() {
  return (
    <Layout>
      <SEO />
      <Hero />
      <Content />
      <Newsletter />
      <Footer />
    </Layout>
  )
}

export default IndexPage
